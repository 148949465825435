<template>
	<div>
		<e-table :tableCols="dataRecordTableConf" :showOperation="true" :dataOrigin="dataOrigin">
			<template #customOperation>
				<el-col :span="1">
					<el-button>导出数据</el-button>
				</el-col>
			</template>
			<template #tableBottom>
				<ul class="flex_r f_jc_b tc bg_999 pt10 pb10 pl20 pr20 cr_fff">
					<li>合计：76</li>
					<li>待初审：1</li>
					<li>已初审：0</li>
					<li>已复审：1</li>
					<li>已终审：0</li>
					<li>已上播：4</li>
					<li>已驳回：1</li>
					<li>销量：0.00</li>
					<li>销售额（元）：￥0.00</li>
				</ul>
			</template>
		</e-table>
	</div>
</template>

<script>
import {dataRecordTableConf} from '@data/anchorData'
export default {
	name: 'DataRecord',
	data() {
		return {
			dataRecordTableConf,
			dataOrigin: {
				data: [
				]
			}
		}
	},
	methods: {
		handleClick() {
		}
	}
}
</script>